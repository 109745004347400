export enum BILLING_TIER {
  FREE = 0,
  DEVELOPER,
  TEAM,
  ENTERPRISE,
}

const labelMap = {
  [BILLING_TIER.FREE]: 'Developer',
  [BILLING_TIER.DEVELOPER]: 'Developer',
  [BILLING_TIER.TEAM]: 'Team',
  [BILLING_TIER.ENTERPRISE]: 'Enterprise',
} as const;

const getLabel = (level: BILLING_TIER) => labelMap[level];

const ordered = [
  BILLING_TIER.FREE,
  BILLING_TIER.DEVELOPER,
  BILLING_TIER.TEAM,
  BILLING_TIER.ENTERPRISE,
];

export const isFreeTier = (tier: BILLING_TIER) =>
  tier === BILLING_TIER.FREE || tier === BILLING_TIER.DEVELOPER;

export default Object.freeze(
  Object.assign(BILLING_TIER, { labelMap, getLabel, ordered })
);
